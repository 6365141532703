<template>
  <div class="mt-56 space-y-16">
    <h2 class="heading-3">
      {{ i18n(translations.deliveryTitle) }}
    </h2>

    <RevCard v-for="item in cartStore.items" :key="item.listingId" class="p-24">
      <div class="body-2-bold mb-24 flex items-center">
        <div
          class="border-static-default-low rounded-sm flex h-40 w-40 items-center justify-center border"
        >
          <RevIllustration
            :alt="item.title"
            class="h-32 w-32"
            :height="32"
            :src="item.image"
            :width="32"
          />
        </div>
        <div class="ml-24">{{ item.title }}</div>
      </div>
      <div v-for="option in item.options" :key="option.type">
        <ShippingOptions
          :listing-id="item.listingId"
          :option="option"
          :show-collection-point-error="showCollectionPointError"
          @select="selectOption"
        />
      </div>
    </RevCard>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue'

import { postUpdateOption } from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import type { ShippingChoice } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'

import ShippingOptions from '../../components/ShippingOptions.vue'
import useHandleUnauthorizedUser from '../../composables/useHandleUnauthorizedUser'
import { useCartStore } from '../../stores/cartStore'

import translations from './Delivery.translations'

const emit = defineEmits(['update'])

const i18n = useI18n()
const tracking = useTracking()

const cartStore = useCartStore()

const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

const showCollectionPointError = computed(
  () =>
    cartStore.isSelectedCollectionPointMissing &&
    Boolean(cartStore.optionWithMissingCollectionPointSelected),
)

const selectOption = async (
  body: {
    listingId: string
    optionType: string
    optionId: string
  },
  choice: ShippingChoice,
) => {
  emit('update', true)

  try {
    await $httpFetch(postUpdateOption, {
      body,
    })

    tracking.trackClick({
      zone: 'cart',
      name: choice.shipperDisplay || 'not-available',
    })

    await cartStore.fetchCart()
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating shipping option',
    )
  } finally {
    emit('update', false)
  }
}

watch(
  () => showCollectionPointError,
  () => {
    if (showCollectionPointError.value) {
      const firstOption = cartStore.optionWithMissingCollectionPointSelected

      if (firstOption) {
        const element = document.getElementById(
          `cart-shipping-option-${firstOption[0]}-${firstOption[1][0]?.choice?.shippingId}`,
        )
        element?.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    }
  },
)
</script>
